@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Font/Gotham-Light.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Font/Gotham-Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Font/Gotham-Medium.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Optima';
  src: url('./fonts/Optima/OPTIMA.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Optima Medium';
  src: url('./fonts/Optima/Optimamedium.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Optima Bold';
  src: url('./fonts/Optima/OPTIMA_B.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Light';
  src: url('./fonts/comfortaa/Comfortaa-Light.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Medium';
  src: url('./fonts/comfortaa/Comfortaa-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Regular';
  src: url('./fonts/comfortaa/Comfortaa-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa-Bold';
  src: url('./fonts/comfortaa/Comfortaa-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'AlexBrush';
  src: url('./fonts/Alex_Brush/AlexBrush-Regular.ttf');
  font-display: swap;
}

:root {

  /* Color */
  /* --primary: #14344A; */
  --primary: #000;
  --primary-light: #14344Af2;
  --black: #000000;
  --white: #fff;
  --lightbg: #F5F8F9;
  --golden: #B9994B;
  /* Fonts */
  --font_1: 'Comfortaa-Light';
  --font_2: 'Comfortaa-Medium';
  --font_3: 'Comfortaa-Bold';

  --primary-font: "Comfortaa-Regular";
  --alex-font: "AlexBrush";
  --primary-base: "Comfortaa-Regular";
  --primary-medium: "Comfortaa-Medium";
  --primary-bold: "Comfortaa-Bold";
}



@media(max-width:1024px) {

  body,
  html {
    overflow-x: hidden;
  }
}



body {
  font-family: var(--font_1);
}

ul li {
  list-style: none;
}

.bottom-10 {
  bottom: -10px;
}

.gap-20 {
  gap: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

input::placeholder {
  color: var(--black) !important;
}

.slick-prev {
  left: -30px !important;
}

.slick-next {
  right: -30px !important;
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  z-index: 99;
}

.golden-btn {
  display: block;
  border: 1px solid var(--golden);
  padding: 12px 24px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--primary-font);
  transition: all .3s ease;
  color: Var(--black);

  &:hover {
    background-color: var(--golden);
    color: Var(--white);
  }
}



/**************** toggle on filter pages style ********************/
.show_toggle_icon_box_inner svg {
  width: 18px;
  height: auto;
  color: var(--white);
  transition: all .4s;
}

.add_transform_n_width {
  transform: translate(-600px, 10px);
  width: 0% !important;
  margin-right: 0 !important;
  display: none;
}

.show_toggle_icon_text {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  width: max-content;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  font-weight: 400;
  color: var(--primary);
  border-radius: 6px;
  font-size: 13px;
  transition: all .3s ease-in-out;
  font-family: var(--font_2);
  letter-spacing: .5px;
  min-width: 150px;
  color: var(--white);
}

.show_toggle_icon_text:hover,
.show_toggle_icon_text:focus,
.show_toggle_icon_text:active {
  background-color: var(--primary);
  color: #fff;
}

.show_toggle_icon_text:hover svg,
.show_toggle_icon_text:focus svg,
.show_toggle_icon_text:active svg {
  color: var(--white);
}

.transition_add {
  transition: all 0.3s ease-in-out;
}

/************************ new product list style ***********************/
.ms_slick_p_list_Slider.slick-initialized {
  overflow: hidden !important;
}

.ms_pro_list_slider.slick-initialized {
  overflow: hidden !important;
  /* z-index: -1; */
}

.ms_slick_p_list_Slider .slick-prev {
  left: -5px !important;
}

.ms_slick_p_list_Slider .slick-next {
  right: -5px !important;
}

.slick-slider {
  width: 100% !important;
}

.ms_slick_p_list_Slider .slick-prev,
.ms_slick_p_list_Slider .slick-next {
  display: none !important;
  width: 40px !important;
  height: 40px !important;
  padding: 10px;
}

.dfl_ddiamond_img {
  width: 100%;
  height: auto;
}

.new_igi_img_desktop {
  cursor: pointer;
  height: auto;
  width: 300px;
}

.ms_slick_p_list_Slider_img_box img {
  width: 100% !important;
  max-width: 65% !important;
  height: auto;
  object-fit: contain;
  /* margin: 0 auto; */
  /* transform: scale(1.5); */
}

.ms_product_item_list_page.rcs_product_item:hover {
  position: absolute !important;
  z-index: 2;
  background-color: #fff;
}

/* .ms_product_item_list_page.rcs_product_item{
  width:462px;
}
.rcs_product_item.ms_product_list_item {
	width: 462.5px;
} */

.ms_pro_list_slider .slick-prev {
  left: 0 !important;
}

.ms_pro_list_slider .slick-next {
  right: 0 !important;
}

.ms_pro_list_slider .slick-prev,
.ms_pro_list_slider .slick-next {
  display: none !important;
  width: 40px !important;
  height: 40px !important;
  padding: 10px;
}

.ms_product_item_list_page.rcs_product_item:hover .ms_pro_list_slider .slick-prev,
.ms_product_item_list_page.rcs_product_item:hover .ms_pro_list_slider .slick-next {
  display: block !important;
}

.ms_appointment_img_box img {
  width: 100%;
  height: auto;
  border: 1px solid #b4c1ca82;
  padding: 3px;
  border-radius: 6px;
  object-fit: contain;
}

/*************************  new product list style end ************************ */

.rcs_product_item:hover .ms_slick_p_list_Slider .slick-prev,
.rcs_product_item:hover .ms_slick_p_list_Slider .slick-next {
  display: block !important;
}

.rcs_product_item.ms_product_list_hover_position:hover .ringSettingHoverBox {
  visibility: visible;
}

.rcs_product_item.ms_product_list_hover_position .ringSettingHoverBox {
  visibility: hidden;
}

.rcs_prod_info {
  display: none;
  position: absolute;
}

.rcs_product_item:hover .rcs_prod_info {
  display: block;
  position: unset;
}

.ms_pl_view_btn {
  border: 1px solid #000;
  padding: 10px 30px;
  font-size: 14px;
  text-transform: capitalize;
  background-color: transparent;
  font-weight: 600;
  margin-top: 10px;
  width: 100%;
}

/* BANNER SLIDER */

.rcs_banner_video .video-box {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  padding: 0;
}

.rcs_banner_video .video-box::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 400px;
  width: 100%;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.rcs_banner_video .video-box .banner_text {
  text-align: center;
  position: absolute;
  z-index: 99;
  bottom: 80px;
  right: 50%;
  transform: translateX(50%);
  width: 100%;
}

.rcs_banner_video .video-box .banner_text h2 {
  font-family: var(--font_3);
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  color: var(--white);
}

.rcs_banner_video .video-box .banner_text p {
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
  margin-bottom: 20px;
}

.rcs_banner_video .video-box .banner_text button {
  text-align: center;
  margin: auto;
  color: var(--white);
}

.rcs_banner_video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rcs_hero_img h1 {
  font-family: var(--primary-base);
  font-size: 50px;
  text-transform: uppercase;
  line-height: 46px;
  color: var(--white);
}

.rcs_hero_img p {
  font-size: 18px;
  padding: 10px 0 20px;
  margin: 0;
  color: var(--white);
}

.rcs_hero_img .outline-base-btn {
  background-color: transparent;
  border: 1px solid var(--white);
}

.outline-base-btn {
  font-size: 16px;
  border-radius: 0;
  font-weight: 800;
  box-shadow: none !important;
  outline: none !important;
  display: inline-block;
  font-family: var(--font_1);
}

.rcs_hero_img .outline-base-btn:hover,
.rcs_hero_img .outline-base-btn:active,
.rcs_hero_img .outline-base-btn:focus {
  background-color: var(--white);
  color: var(--black);
  border-color: var(--white);
}

.outline-base-btn.large {
  padding: 12px 20px;
}

.outline-base-btn.small {
  padding: 5px 15px;
}

.rcs_hero_slider {
  min-height: 800px;
  display: grid;
  align-items: center;
  background-size: cover;
  background-position: right top;
}

.heading-wrapper {
  padding-bottom: 30px;
}

.heading-wrapper span {
  font-size: 20px;
  line-height: 22px;
  font-family: var(--primary-base);
  padding-bottom: 10px;
  font-weight: 600;
  display: block;
  color: var(--black);
}

.about_manuel_section {
  max-width: 1400px;
  margin: auto;
}

.about_manuel_section h2,
.heading-wrapper h2 {
  font-family: var(--primary-font);
  font-weight: 600;
  color: var(--black);
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 15px;
}

.heading-wrapper p {
  font-size: 16px;
  line-height: 1.8;
  font-family: var(--primary-font);
  font-weight: 400;
  color: var(--black);
  margin: 0;
}

.section-space {
  padding: 100px 0;
}

.rcs_footer .showcase-container,
.rcs_footer_bottom .showcase-container,
.home-showcase-container {
  max-width: 1600px;
  margin: auto;
}

.showcase-container {
  max-width: 1850px;
  margin: auto;
}

.LandingPageShocase .showcase-container {
  max-width: 1850px;
}

.diamonds-collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  row-gap: 15px;
  position: relative;
}


.diamonds-collection .diamond-item {
  text-align: center;
  cursor: pointer;
}

.diamonds-collection .diamond-item img {
  width: 100px;
  margin: auto;
}

.diamonds-collection .diamond-item p {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  font-family: var(--font_1);
  color: var(--black);
  padding-top: 10px;
  margin-bottom: 0;
}

.inner-shop-diamond-shape {
  padding: 0px 60px;
}

.diamonds-item-brief {
  width: 80%;
  margin: 0 auto;
}

.diamonds-item-brief .content h2 {
  font-family: var(--primary-medium);
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1px;
}

.diamonds-item-brief .content p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.diamonds-item-brief img {
  max-width: 280px;
  height: 280px;
  object-fit: contain;
  margin-right: 20px;
}

.diamonds-item-brief .content .outline-base-btn {
  border: 1px solid var(--black);
  color: var(--black);
}

.diamonds-item-brief .content .outline-base-btn:hover {
  color: var(--white);
  background-color: var(--black);
}

.shop-diamond-by-shape {
  position: relative;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

/* .shop-diamond-by-shape::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
} */

.together_section {
  width: 100%;
  max-width: 1920px;
  padding: 0;
  position: relative;
  /* display: flex;
  align-items: center; */

}

.together_section .right_section {
  width: 100%;
  position: relative;
}

.together_section .left_section {
  max-width: 452px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-25%, -50%);
  text-align: left;
}

.together_section .left_section button,
.together_section .left_section h2 {
  color: var(--white);
  text-align: left;
}

.together_section .left_section p {
  color: #DFDFDF;
  text-align: left;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}

.two_ring_cards_section .card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.two_ring_cards_section .card_container .img-box {
  width: 540px;
  height: 642px;
  overflow: hidden;
  cursor: pointer;
  transition: all .3s ease;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s ease;
  }

  &:hover img {
    transition: all .3s ease;
    scale: 1.1;
  }
}

.two_ring_cards_section .card_container a h3 {
  margin: 30px 0 20px 0;
  font-size: 30px;
  font-weight: 600;
  color: var(--black);
  font-family: var(--primary-font);
  line-height: 1.2;
  text-align: center;
  transition: all .3s ease;

  &:hover {
    letter-spacing: 1px;
    transition: all .3s ease;
  }
}



.explore-collection .slick-initialized .slick-slide {
  padding: 0 15px;
}

.explore-collection .item {
  position: relative;
  transition: all .3s ease;


  &:hover .link {
    letter-spacing: 1px;
    transition: all .3s ease;
  }

  &:hover.box-overlay {
    transition: all .3s ease;
    opacity: 0;
  }

}

.explore-collection .item .box-overlay {
  transition: all .3s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 20%;
  top: 0;
  right: 0;
}

.explore-collection .item img {
  max-width: 100%;
  transition: all .5s ease;
}

.explore-collection .item:hover img {
  transform: scale(1.1);
}

.explore-collection .item a {
  display: block;
}

.explore-collection .item .link {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 50px;
  font-weight: 500;
  font-family: var(--primary-font);
  line-height: 1.2;
  color: var(--white);
  display: block;
}



.explore-collection .item .img-box {
  overflow: hidden;
  padding-top: 0px;
  height: 500px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* .design-diamond-ring-section {
  background-color: var(--primary);
} */

.design-diamond-ring-section .heading-wrapper * {
  color: var(--white);
}

.design-diamond-ring-section .heading-wrapper p {
  width: 60%;
}

.design-diamond-ring-section .heading-wrapper h2 {
  font-size: 50px;
  text-transform: uppercase;
  line-height: 1;
}

.design-diamond-ring-section .heading-wrapper span {
  text-transform: uppercase;
}

.design-diamond-ring-section .heading-wrapper span {
  font-weight: 400;
}

.design-diamond-ring-section .content span {
  display: inline-block;
  opacity: 0.15;
  font-size: 50px;
  line-height: 60px;
  font-family: var(--primary-bold);
  padding-bottom: 15px;
}

.design-diamond-ring-section .content * {
  color: var(--white);
}

.design-diamond-ring-section .content h3 {
  font-family: var(--primary-base);
  font-size: 26px;
  line-height: 25px;
  padding-bottom: 15px;
  margin: 0;
}

.design-diamond-ring-section .content p {
  padding-bottom: 25px;
  font-size: 14px;
  margin: 0;
  width: 80%;
}

.design-diamond-ring-section .content .outline-base-btn {
  border: 1px solid var(--white);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  font-family: var(--font_1);
}

.design-diamond-ring-section .content .outline-base-btn:hover {
  background: var(--white);
  color: #000;
}

.design-diamond-ring-section,
.design-diamond-ring-section .video-item {
  position: relative;
}

.design-diamond-ring-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("./img/shape.png") no-repeat center/cover;
  width: 200px;
  height: 200px;
}

.design-diamond-ring-section .video-item video {
  width: 100%;
}

.design-diamond-ring-section .video-item .content {
  position: absolute;
  top: 0;
  left: 50px;
}


.manuel_exprience_section .manuel_exprience_container .manuel_exprience_box {
  max-width: 330px;
  text-align: center;
  flex: auto;
  /* border-right: 1px solid #e6e6e6; */
}

.manuel_exprience_section .manuel_exprience_container .manuel_exprience_box:last-child {
  border-right: 1px solid transparent;
}

.manuel_exprience_section .manuel_exprience_container .manuel_exprience_box h2 {
  font-family: var(--primary-font);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.manuel_exprience_section .manuel_exprience_container .manuel_exprience_box img {
  margin-bottom: 20px;
}

.manuel_exprience_section .manuel_exprience_container .slick-initialized .slick-slide {
  padding: 0 20px;
  border-right: 1px solid #e6e6e6;
}

.our_legacy_section .legacy_container .slick-initialized .slick-slide {
  padding: 0 20px;
}

.manuel_exprience_section .manuel_exprience_container .slick-dots,
.our_legacy_section .legacy_container .slick-dots {
  bottom: -35px !important;
}

.shop-ring-by-style .item a {
  display: block;
  font-family: var(--primary-font);
  color: var(--black);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
  font-weight: 700;
}

.shop-ring-by-style .item:hover a {
  font-weight: 400;
}

.shop-ring-by-style {
  position: relative;
}

.shop-ring-by-style .slick-slider .slick-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  width: 30px;
  height: auto;
}

/* .shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
  right: 40px;
} */

.gemstone-glamour .heading-wrapper h2,
.gemstone-glamour .heading-wrapper span {
  text-transform: uppercase;
}

.gemstone-glamour .heading-wrapper p {
  width: 50%;
  margin: 0 auto;
}

.gemstone-glamour .item {
  text-align: center;
}

.gemstone-glamour .item p {
  font-size: 12px;
  color: var(--white);
  padding-top: 10px;
}

.gemstone-glamour {
  background: url("./img/gemstone-bg.webp") no-repeat center/cover;
}

.gemstone-glamour .heading-wrapper * {
  color: var(--white);
}

.gemstone-glamour .outline-base-btn {
  color: var(--white);
  border: 1px solid var(--white);
}

.gemstone-glamour .outline-base-btn:hover {
  background: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
}

.custom-design .heading-wrapper {
  width: 80%;
  margin: 0 auto;
}

.custom-design .outline-base-btn {
  border: 1px solid var(--black);
  text-transform: uppercase;
}

.custom-design .outline-base-btn:hover {
  background: var(--black);
  color: var(--white);
}

.consult-specialist-section .inner-wrap {
  width: 90%;
  height: 100%;
  margin-right: auto;
  display: grid;
  place-content: center;
  justify-content: center;
}


.consult-specialist-section .inner-wrap span {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--primary-base);
  text-transform: uppercase;
}

.consult-specialist-section .inner-wrap h2 {
  /* font-family: var(--primary-medium); */
  /* text-transform: uppercase; */
  /* font-size: 50px; */
  /* line-height: normal; */
}

.consult-specialist-section .inner-wrap p {
  font-size: 16px;
  line-height: 1.8;
  font-family: var(--primary-font);
  font-weight: 400;
}

.consult-specialist-section .inner-wrap .btn-group {
  margin-top: 15px;
  gap: 15px;
}

.consult-specialist-section .inner-wrap .btn-group .golden-btn {
  padding: 12px 16px;
}

/* .consult-specialist-section .inner-wrap a {
  border: 1px solid var(--white);
  width: fit-content;
  margin: 0 auto;
}

.consult-specialist-section .inner-wrap a:hover {
  background: var(--white);
  color: var(--black);
} */

.about_manuel_section .left_section .box {
  max-width: 530px;

}

.about_manuel_section .left_section .box p {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
  line-height: 2;
  font-family: var(--primary-font);
  margin-bottom: 10px;
}

.about_manuel_section .left_section .box {
  padding: 40px 0;
}

.about_manuel_section .right_section h2 {
  font-size: 50px;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
  margin: 0;
  font-family: var(--alex-font);
}

.about_manuel_section .right_section .box {
  max-width: 600px;
  margin-left: auto;
}

.about_manuel_section .right_section .img-box .logo {
  margin-bottom: 25px;
}

.about_manuel_section .right_section .img-box {
  max-width: 600px;
}

.our_legacy_section {
  padding: 80px 0 !important;
  background: #FAFAFA;
}

.our_legacy_section .legacy_container {
  padding: 0 15px;
  margin: auto;
  max-width: 1280px;
}



.slick-dots li {
  margin: 0 6px !important;
  overflow: hidden;
  border-radius: 50%;
}

.slick-dots li button,
.slick-dots li,
.slick-dots li button:before {
  width: 8px !important;
  height: 8px !important;
  gap: 8px;
  opacity: 0.3;
  background: #1B1B1B;
  border-radius: 50%;
}


.slick-dots li.slick-active li {
  width: 8px !important;
}

.slick-dots li.slick-active {
  background-color: var(--black);
  opacity: 1;
}

.our_legacy_section .legacy_container .box {
  padding: 20px 20px 30px 20px;
  border: 1px solid #D6D6D6;
  background: #FFFFFF;
  max-width: 404px;
  text-align: center;
}

.our_legacy_section .legacy_container .box h2 {
  padding-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  font-family: var(--primary-font);
  color: var(--black);
  white-space: nowrap;
}

.our_legacy_section .legacy_container .box p {
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 135px;
  max-width: 80%;
  margin: 0 auto 0 auto;

}

.our_legacy_section .legacy_container .box a {
  font-family: var(--primary-font);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 20px;
  display: block;
  color: var(--golden);
}

.our_legacy_section .legacy_container .img-box {
  max-width: 364px;
  width: 100%;
  height: 390px;
}

.our_legacy_section .legacy_container .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.promises-section {
  position: relative;
}

.promises-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.promises-section .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promises-section .row div[class*="col-"] {
  position: relative;
}

.promises-section .row div[class*="col-"]:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 2px;
  height: calc(100% + 40px);
  background-color: var(--black);
  opacity: 0.3;
}

.promises-section .row.align-items-center>div[class*="col-"]:first-child:before,
.promises-section .row div[class*="col-"]:last-child:before {
  display: none;
}

.promises-section .item p {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 5%;
  font-family: var(--font_2);
  margin-left: 15px;
}

.rcs_customer_say_section .outline-base-btn {
  background: transparent;
  border: 1px solid var(--black);
  color: var(--black);
}

.rcs_customer_say_section .outline-base-btn:hover {
  background: var(--black);
  color: var(--white);
}

.rcs_customer_say_section .rcs_customer_slide_text_content p {
  font-size: 12px;
  font-family: var(--primary-font);
  font-weight: 300;
  line-height: 2.2;
  color: var(--black);
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}

.rcs_customer_say_section .rcs_customer_slide_text_content a {
  color: var(--black);
  font-family: var(--primary-font);
  font-weight: 300;
  /* text-decoration: underline; */
}

.rcs_customer_say_section .rcs_customer_slide_text_content p span {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rcs_customer_say_section .rcs_customer_review_slider .slick-prev,
.rcs_customer_say_section .rcs_customer_review_slider .slick-next {
  top: calc(100% + 30px) !important;
}

.rcs_customer_say_section .rcs_customer_review_slider .slick-prev {
  left: calc(50% - 40px) !important;
}

.rcs_customer_say_section .rcs_customer_review_slider .slick-next {
  right: calc(50% - 40px) !important;
}


.rcs_customer_say_section .rcs_customer_review_slide_title h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 3px;
  font-family: var(--primary-font);
}

.rcs_customer_say_section .rcs_customer_review_slide_title p {
  margin: 0;
  font-family: var(--primary-font);
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
}

.rcs_customer_say_section .MuiRating-sizeMedium {
  font-size: 1rem;
}

.rcs_customer_say_section .name-charAt {
  font-size: 20px;
  font-family: var(--font_1);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #5d4037;
  text-align: center;
  line-height: 35px;
  color: var(--white);
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.rcs_customer_say_section .name-charAt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #5d4037;
  opacity: 0.1;
  border-radius: 100%;
  z-index: -1;
}

.rcs_customer_say_section .slick-slide {
  padding: 10px;
}

.rcs_customer_say_section .rcs_customer_review_slide_item {
  padding: 30px;
  min-height: 263px;
  /* border: 1px solid #DDE0E1; */
  /* box-shadow: rgb(0 0 0 / 3%) 0px 4px 6px -1px, rgb(0 0 0 / 3%) 0px 2px 4px -1px; */
  box-shadow: 0 0 8px #cccccc57;
}

.insta-iframe {
  height: 400px;
}

.rcs_signup_to_input_field {
  margin-bottom: 20px;
}

.rcs_signup_to_input_field input {
  height: 50px;
  padding: 10px 16px;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  background: var(--white);
  border: 1px solid linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.rcs_signup_to_input_field input::placeholder {
  color: var(--black);
  opacity: 1;
  font-size: 18px;
}

.rcs_signup_to_input_field input:active,
.rcs_signup_to_input_field input:focus {
  border: 1px solid rgb(20, 52, 74, 1);
}


.rcs_signup_to_input_field button {
  background: var(--black) !important;
  color: var(--white);
  font-family: var(--font_1);
  letter-spacing: 1px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 20.07px;
}

.rcs_signup_to_section {
  background: url("./img/newsletter-bg.jpg") no-repeat center/cover;
}

section.shop-ring-by-style .slick-slide img {
  width: 100%;
  padding: 0 10px;
}




/* ---- cookies css --------- */
#rcc-decline-button,
.btn-none-border {
  position: relative;
  border: none !important;
  outline: 0 !important;
  background-color: transparent !important;
  color: var(--primary) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: var(--primary-font) !important;
  transition: all .3s ease !important;

  

  &::after {
    top: 100%;
    right: 50%;
    width: 100%;
    transform: translateX(50%);
    height: 1px;
    position: absolute;
    content: '';
    background-color: currentColor;
  }

  &:hover {
    color: var(--golden) !important;
  }
}

.CookieConsent {
  background-color: var(--white) !important;
  color: var(--black) !important;
  align-items: center !important;
  justify-content: end !important;
  z-index: 99 !important;
  max-width: 550px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 50px 50px 50px 100vw !important;
  z-index: 99999 !important;
  width: 90% !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);

  h2 {
    font-family: var(--primary-font);
    font-weight: 600;
    color: var(--black);
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    color: var(--black);
    font-weight: 400;
    line-height: 2;
    font-family: var(--primary-font);
    margin-bottom: 10px;
  }

  #rcc-confirm-button {
    height: 40px;
    width: 150px;
    border: 1px solid var(--golden) !important;
    padding: 12px 24px;
    background-color: transparent !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px !important;
    font-family: var(--primary-font);
    transition: all .3s ease;
    color: Var(--black) !important;
    border-radius: 0 !important;

    &:hover {
      color: Var(--white) !important;
      background-color: var(--golden) !important;

    }


  }

  &::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh !important;
    background-color: #57575734;
    z-index: -1;
  }

  .prefrenceBtn {
    left: 20px;
    position: absolute;
    top: calc(100% - 45px);
  }
}

.cookiesModal {
  z-index: 111111;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;

  .golden-btn {
    font-size: 14px !important;
  }
  
  .modal-view {
    max-height: calc(45vw - 0px);
    overflow-y: auto;
  }

  /* &::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh !important;
    background-color: #57575734;
    z-index: -1;
  } */
}

.react-switch-bg {
  & svg {
    display: none !important;
  }
}

@media (min-width:1280px) and (max-width:1439px) {

  .ms_pro_list_slider .slick-prev,
  .ms_pro_list_slider .slick-next,
  .ms_slick_p_list_Slider .slick-prev,
  .ms_slick_p_list_Slider .slick-next {
    width: 35px !important;
    height: 35px !important;
  }


  .home-showcase-container,
  .LandingPageShocase .showcase-container,
  .rcs_footer .showcase-container,
  .rcs_footer_bottom .showcase-container,
  .showcase-container {
    padding: 0px;
    max-width: 96%;
  }

  .consult-specialist-section .inner-wrap .btn-group .golden-btn {
    padding: 12px;
  }

  .together_section .left_section {
    transform: translate(0%, -50%);
    padding: 0 30px;
  }

  .two_ring_cards_section .card_container .img-box {
    width: 100%;
  }

  .consult-specialist-section .inner-wrap {
    width: 100%;
  }

  .our_legacy_section .legacy_container {
    gap: 30px;
  }

  .rcs_hero_slider {
    min-height: 600px;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 250px;
    height: 250px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 14px;
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    width: 100%;
  }

  .gemstone-glamour .item img {
    width: 85%;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 45px;
  }

  section.design-diamond-ring-section .content.pl-3.pr-5 {
    padding-right: 0px !important;
  }

  .section-space {
    padding: 60px 0;
  }

  .design-diamond-ring-section {
    padding-bottom: 70px !important;
  }

  .design-diamond-ring-section .video-item video {
    /* position: relative; */
    /* top: 120px; */
  }

  .design-diamond-ring-section .video-item .content {
    padding-right: 100px;
  }

  .design-diamond-ring-section .content p {
    font-size: 13px;
    width: 100%;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 12px;
  }

  .about_manuel_section h2,
  .heading-wrapper h2 {
    font-size: 32px;
  }

  .heading-wrapper p {
    font-size: 14px;
  }

  .custom-design .heading-wrapper {
    width: 79%;
  }

  /* .consult-specialist-section .inner-wrap h2 {
    font-size: 35px;
  } */

  .consult-specialist-section .showcase-container>div {
    width: 100%;
    overflow: hidden;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    height: 130px;
  }

  .promises-section .item p {
    font-size: 16px;
  }

  .promises-section .heading-wrapper h2 {
    font-size: 28px;
  }

  .promises-section .row div[class*="col-"]:before {
    width: 1px;
    height: calc(100% + 20px);
  }

  .insta-iframe {
    height: 300px;
  }

}


@media (max-width:767px) {

  .rcs_banner_video .video-box {
    /* height: 468px !important; */
  }

  .rcs_banner_video .video-box .banner_text p {
    margin-bottom: 8px;
  }
}

@media (max-width:991px) {

  .brand-logo {
    height: 30px;
    padding-left: 20px;
    width: auto;
    object-fit: contain;
  }

  .ms_pro_list_slider .slick-prev,
  .ms_pro_list_slider .slick-next,
  .ms_slick_p_list_Slider .slick-prev,
  .ms_slick_p_list_Slider .slick-next {
    width: 40px !important;
    height: 40px !important;
  }

  .heading-wrapper {
    padding-bottom: 20px;
  }

  .rcs_banner_video .video-box .banner_text {
    bottom: 15px;
  }

  .rcs_banner_video .video-box .banner_text h2 {
    font-size: 25px;
  }

  .rcs_banner_video .video-box .banner_text p {
    font-size: 13px;
  }

  .manuel_exprience_section .manuel_exprience_container .manuel_exprience_box {
    max-width: 100%;
    text-align: center;
    flex: auto;
    border-right: 1px solid transparent;
  }

  .manuel_exprience_section .manuel_exprience_container .slick-initialized .slick-slide,
  .our_legacy_section .legacy_container .slick-initialized .slick-slide {
    padding: 0 20px;
    border-right: 1px solid transparent;
  }


  .our_legacy_section {
    padding: 30px 0 60px 0 !important;
  }

  .heading-wrapper {
    padding-bottom: 20px;
  }

  .our_legacy_section .legacy_container .img-box,
  .our_legacy_section .legacy_container .box {
    max-width: 100%;
  }

  .manuel_exprience_section .manuel_exprience_container .slick-initialized .slick-slide,
  .our_legacy_section .legacy_container .slick-initialized .slick-slide {
    padding: 0 10px;
  }

  .together_section .right_section::after {
    content: "";
    position: absolute;
    background: linear-gradient(180.09deg, rgba(0, 0, 0, 0) 46.63%, #000000 95.08%);
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 200px;
  }

  .together_section .left_section h2 {
    text-align: center;
  }

  .together_section .left_section p {
    text-align: center;
  }

  .together_section .left_section .golden-btn {
    margin: auto;
  }

  .together_section .left_section {
    background-color: var(--black);
    position: initial;
    transform: translate(0, 0);
    max-width: 100%;
    padding: 50px;
  }

  .together_section .left_section div {
    max-width: 450px;
    margin: auto;
  }

  .together_section .left_section .heading-wrapper {
    width: 100%;
    text-align: center;
  }

  .consult-specialist-section .inner-wrap .btn-group {
    justify-content: center;
  }

  .about_manuel_section .left_section .box {
    text-align: center;
    margin: auto;
    padding: 20px 0 0 0;
  }

  .about_manuel_section .left_section .box button {
    margin: auto;
  }

  .about_manuel_section .right_section .box {
    margin: auto;
  }

  .about_manuel_section .right_section .img-box .logo {
    margin-bottom: 15px;
    max-width: 70%;
  }

  .about_manuel_section .right_section h2 {
    font-size: 35px;
  }

  .about_manuel_section .left_section .box p {
    font-size: 14px;
  }

  .rcs_banner_video .video-box {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
  }



  .wistia_responsive_padding {
    margin-bottom: 30px;
  }

  .RingBuilderHome .content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 13px;
  }

  .diamonds-collection .diamond-item img {
    width: 100%;
  }

  .section-space {
    padding: 50px 0;
  }

  .home-showcase-container,
  .LandingPageShocase .showcase-container,
  .rcs_footer .showcase-container,
  .rcs_footer_bottom .showcase-container,
  .showcase-container {
    padding: 0px 15px;
    max-width: 900px;
  }

  .rcs_hero_img h1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  .rcs_hero_img p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-top: 0;
  }

  .rcs_hero_img button {
    font-size: 14px;
    font-weight: 500;
    text-align: center;

  }

  section.explore-collection.section-space .row {
    margin: 0;
  }

  .rcs_hero_slider {
    min-height: 468px;
    background-position: center;
    padding-bottom: 60px;
    align-items: end;
  }

  .about_manuel_section h2,
  .heading-wrapper h2 {
    font-size: 30px;
  }

  .diamonds-collection::after,
  .shop-diamond-by-shape::after {
    display: none;
  }

  .diamonds-collection .diamond-item .desc {
    font-family: 'Gotham';
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }

  .diamonds-collection .diamond-item a {
    font-family: 'Gotham';
    font-size: 12px;
    font-weight: 500;
    color: var(--black);
    border: 1px solid var(--black);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .design-diamond-ring-section .video-item {
    margin-bottom: 20px;
  }

  .slick-dots li button:before {
    width: 10px !important;
    content: ' ' !important;
    background: #000 !important;
    height: 1px !important;
  }


  .shop-diamond-by-shape .heading-wrapper {
    padding-bottom: 0;
  }

  .explore-collection .item a {
    font-size: 14px;
  }

  .heading-wrapper {
    width: 74%;
    margin: auto;
    text-align: center !important;
  }

  /* design-diamond-ring-section */

  .design-diamond-ring-section .heading-wrapper {
    text-align: center;
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper p {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper span {
    font-size: 20px;
    line-height: inherit;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 30px;
    font-weight: 550;
    line-height: 28px;
  }

  .mobile-slider-ringsetting-home .item {
    text-align: center;

  }

  .mobile-slider-ringsetting-home .item img {
    width: 50%;
    margin: 20px auto 30px;
  }

  .mobile-slider-ringsetting-home .item p {
    margin: auto;
  }

  .mobile-slider-ringsetting-home .item .content {
    padding: 0 !important;
  }

  .mobile-slider-ringsetting-home .slick-dots {
    position: absolute;
    top: 35px;
    bottom: auto;
    display: flex !important;
  }

  .mobile-slider-ringsetting-home .slick-dots li {
    width: 33%;
    height: 100%;
    border-right: 2px solid #ffffff51;
  }

  .mobile-slider-ringsetting-home .slick-dots li:last-child {
    border-right: 0;
  }

  .mobile-slider-ringsetting-home .slick-dots li a {
    font-family: var(--primary-font);
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: auto;
    background: #ffffff41;
    color: #ffffff97;
  }

  .mobile-slider-ringsetting-home .slick-dots li.slick-active a {
    background: var(--white);
    color: var(--black);
  }

  .mobile-slider-ringsetting-home .slick-list {
    padding-top: 80px;
  }

  .design-diamond-ring-section {
    padding-bottom: 50px !important;
  }

  section.RingBuilderHome.design-diamond-ring-section.section-space .align-items-center.row {
    flex-direction: column-reverse;
  }

  .design-diamond-ring-section .video-item .content {
    position: static;
  }

  .design-diamond-ring-section .video-item video {
    width: 100%;
    /* position: relative; */
    /* top: -85px; */
    /* margin-bottom: -90px; */
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 0 5px;
    width: 100%;
  }

  section.shop-ring-by-style.section-space .slick-dots {
    bottom: -45px;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
  }

  .gemstone-glamour .heading-wrapper {
    width: 100%;
  }

  .heading-wrapper span {
    font-size: 20px;
  }

  .gemstone-glamour {
    background-position: left;
  }

  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .custom-design .heading-wrapper {
    width: 78%;
    padding: 20px 0;
  }

  .consult-specialist-section .row {
    flex-direction: column-reverse;
  }

  .consult-specialist-section {
    padding: 20px;
  }

  .consult-specialist-section .inner-wrap span {
    font-size: 20px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap p {
    font-size: 14px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap {
    width: 100%;
    padding: 20px 0;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content a {
    font-size: 12px;
  }

  .insta-iframe {
    height: 210px;
  }


  .rcs_signup_to_input_field input,
  .rcs_signup_to_input_field button {
    min-width: 100% !important;
    /* margin-top: 10px; */
  }

  form.gs_news_form.w-100 .input-group {
    display: block;
  }

  .rcs_signup_to_section {
    background: url('../Assets/img/mb-bg-news.png');
  }

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_search_bar.mobileSearchBox {
    display: none;
  }

  .rcs_signup_to_input_field {
    margin-top: 15px;
  }

}

@media (min-width:1440px) and (max-width:1600px) {
  .together_section .left_section {
    transform: translate(0%, -50%);
    padding-right: 20px;
  }

  .new_igi_img_desktop {
    width: 150px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {
  .rcs_hero_slider {
    min-height: 635px;
  }

  .new_igi_img_desktop {
    width: 250px;
  }

  section.explore-collection.section-space .col-md-3.col-6 {
    padding: 0 8px;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    height: 130px;
  }

  .rcs_customer_say_section .rcs_customer_review_slide_title h3 {
    font-size: 13px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .promises-section .item p {
    font-size: 17px;
  }

  .about_manuel_section h2,
  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .video-item video {
    margin-top: 55px;
  }

  section.design-diamond-ring-section.section-space.pb-0 {
    padding-bottom: 100px !important;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .diamonds-item-brief {
    width: 100%;
  }



  .rcs_bg-slide li {
    font-size: 10px !important;
    padding-bottom: 0px !important;
  }

  .appointment-report-btn .rcs_border_btn {
    font-size: 12px !important;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 70px !important;
    height: 70px !important;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap !important;
  }

  /* .consult-specialist-section .inner-wrap h2 {
    font-size: 36px;
  } */

  .insta-iframe {
    height: 320px;
  }
}

@media (min-width:992px) and (max-width:1024px) {
  .new_igi_img_desktop {
    width: 150px;
  }
}

@media (min-width:769px) and (max-width:991px) {
  .explore-collection .item .link {
    font-size: 35px;
  }

  .explore-collection .item .img-box {
    height: 425px;
  }

  .manuel_exprience_section .manuel_exprience_container.slick-initialized .slick-slide,
  .our_legacy_section .legacy_container .slick-initialized .slick-slide {
    padding: 0 10px;
  }
}

@media (min-width:991px) and (max-width:1279px) {
  .show_toggle_icon_text {
    padding: 5px 12px;
    font-size: 11px;
    min-width: 125px;
  }

  .show_toggle_icon_box_inner svg {
    width: 15px;
  }

  .together_section .left_section h2 {
    font-size: 24px;
  }

  .together_section .left_section {
    max-width: 380px;
    transform: translate(0%, -50%);
  }

  .consult-specialist-section .inner-wrap p {
    margin-bottom: 10px;
  }

  .consult-specialist-section .inner-wrap .btn-group {
    margin-top: 10px;
  }

  .consult-specialist-section .inner-wrap .btn-group {
    flex-wrap: wrap;
  }

  .consult-specialist-section .inner-wrap .btn-group button {
    max-width: 282px;
    width: 100%;
    font-size: 16px;
  }

  .our_legacy_section .legacy_container .img-box,
  .our_legacy_section .legacy_container .box {
    max-width: 100%;
  }

  .explore-collection .item .link {
    font-size: 35px;
  }

  .explore-collection .item .img-box {
    height: 460px;
  }

  .about_manuel_section .right_section .box {
    margin: auto;
  }

  .two_ring_cards_section .card_container {
    gap: 30px;
  }

  .two_ring_cards_section .card_container .img-box {
    width: 450px;
    height: 534px;
  }

  .diamonds-collection .diamond-item img {
    margin: auto;
  }

  .rcs_hero_img h1 {
    font-size: 40px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .home-showcase-container,
  .LandingPageShocase .showcase-container,
  .rcs_footer .showcase-container,
  .rcs_footer_bottom .showcase-container,
  .showcase-container {
    /* padding: 0px; */
    overflow: hidden;
    max-width: 96%;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 200px;
    height: 200px;
    margin-right: 0px;
  }

  .diamonds-item-brief {
    width: 100%;
  }

  .outline-base-btn {
    font-size: 12px;
  }

  .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .gemstone-glamour .item {
    text-align: center;
    padding: 0 20px;
  }

  .about_manuel_section h2,
  .heading-wrapper h2 {
    font-size: 30px;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 40px;
  }

  .section-space {
    overflow-x: hidden;
  }

  section.rcs_customer_say_section .slick-next {
    right: 0;
  }

  section.rcs_customer_say_section .slick-prev {
    left: 0;
  }

  .consult-specialist-section .inner-wrap {
    padding: 30px 0;
    width: 100%;
  }

  .section-space {
    padding: 50px 0;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
    margin: 0 auto;
  }
}

.rcs_customer_review_slide_img img {
  width: auto;
}

@media (max-width:991px) {
  .new_igi_class_mobile {
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: left;
    margin: 0 auto !important;
    margin-left: 10px;
    position: absolute;
    right: 35px;
    top: 40px;
    width: 110px;
  }
}

@media (min-width:767px) and (max-width:991px) {
  .two_ring_cards_section .card_container {
    /* flex-direction: column; */
    gap: 20px;
  }

  .two_ring_cards_section .card_container div {
    flex: auto;
  }

  .two_ring_cards_section .card_container .img-box {
    width: 100%;
    height: 450px;
  }

  .two_ring_cards_section .card_container a h3 {
    margin: 20px 0 15px 0;
    font-size: 24px;
  }
}

@media (min-width:767px) and (max-width:1023px) {
  section {
    overflow-x: hidden;
  }

  .diamonds-collection .diamond-item img {
    /* width: auto !important; */
    margin: auto;
  }

  .diamonds-collection .diamond-item .desc {
    width: 75%;
    margin: auto !important;
  }

  .RingBuilderHome.design-diamond-ring-section .row .col-md-5.col-12,
  .RingBuilderHome.design-diamond-ring-section .row .col-md-7.col-12,
  section.gemstone-glamour .col-md-6,
  .consult-specialist-section .col-md-6,
  .custom-design .col-md-6,
  #rcs_footer_bottom .col-lg-4.col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-design .col-md-6 img {
    width: auto;
  }

  .insta-iframe {
    height: 175px;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .gemstone-glamour {
    background-size: cover;
    background-position: right;
  }
}


@media (min-width:320px) and (max-width:576px) {
  .consult-specialist-section .inner-wrap .btn-group button {
    max-width: 100%;
  }

  .rcs_banner_video .video-box {
    /* height: 480px; */
  }

  .our_legacy_section .legacy_container .img-box {
    height: 280px;
  }

  .rcs_banner_video .video-box video {
    /* height: max-content; */
    height: auto;
  }



  .rcs_myaccount_section .MuiTabs-flexContainer {
    overflow: scroll;
  }

  .rcs_banner_video .video-box::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%); */
    /* background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 5) 10%,
        rgba(0, 0, 0, 5) 100%,
        #000000 100%); */
    background-color: #00000040;

  }

  .rcs_banner_video .video-box .banner_text {
    padding: 0 20px;
  }
}

@media (min-width:540px) and (max-width:991px) {
  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: nowrap;
  }

  .gemstone-glamour .item img {
    width: 70%;
  }

  .gemstone-glamour .item p {
    font-size: 10px;
  }

  .gemstone-glamour {
    background-image: linear-gradient(45deg, #2a3340, #1e252e);
  }
}

@media (min-width:769px) and (max-width:990px) {
  .diamonds-collection .diamond-item .desc {
    line-height: 20px;
    min-height: 68px;
  }
}

@media(min-width:1150px) and (max-width:1440px) {
  .show_toggle_icon_text {
    padding: 5px 12px;
    font-size: 11px;
    min-width: 125px;
  }

  .new_igi_img_desktop {
    width: 230px;
    height: auto;
  }

  .show_toggle_icon_box_inner svg {
    width: 15px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {

  .LandingPageShocase .showcase-container,
  .rcs_footer .showcase-container,
  .rcs_footer_bottom .showcase-container,
  .showcase-container {
    /* max-width: 96%; */
    max-width: 1850px;
  }

  .section-space {
    padding: 80px 0;
  }
}

@media(min-width:576px) and (max-width:768px) {
  .our_legacy_section .legacy_container .img-box {
    height: 350px;
  }

  .explore-collection .item .img-box {
    height: 380px;
  }
}

@media(max-width:768px) {

  .two_ring_cards_section .card_container {
    /* flex-direction: column; */
    gap: 20px;
  }

  .our_legacy_section .legacy_container .box h2 {
    white-space: wrap;
  }

  .consult-specialist-section .inner-wrap p {
    margin-bottom: 10px;
  }

  .consult-specialist-section .inner-wrap .btn-group {
    margin-top: 10px;
  }

  .consult-specialist-section .inner-wrap .btn-group {
    flex-wrap: wrap;
    justify-content: center;
  }

  .consult-specialist-section .inner-wrap .btn-group button {
    max-width: 282px;
    width: 100%;
    font-size: 16px;
  }

  .two_ring_cards_section .card_container div {
    flex: auto;
  }

  .two_ring_cards_section .card_container .img-box {
    width: 100%;
    height: 380px;
  }

  .two_ring_cards_section .card_container a h3 {
    font-size: 24px;
  }

  .explore-collection .item .link {
    font-size: 30px;
  }

  .heading-wrapper {
    width: 85%;
  }

  .about_manuel_section h2,
  .heading-wrapper h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .inner-shop-diamond-shape {
    padding: 0 40px;
  }
}

@media(max-width:576px) {
  .CookieConsent {
    text-align: center !important;
    /* display: block !important; */

    h2 {
      font-size: 26px !important;
    }

    p {
      font-size: 14px !important;
    }

    button {
      margin-top: 0 !important;
    }

    #rcc-decline-button,
    #rcc-confirm-button {
      margin: 10px !important;
    }

    #rcc-confirm-button {
      width: 90px;
    }

    .prefrenceBtn {
      top: calc(100% - 38px);
    }
  }

  .heading-wrapper {
    width: 96%;
  }

  .rcs_customer_say_section .rcs_customer_review_slide_item {
    padding: 20px;
  }

  .our_legacy_section .legacy_container .box h2 {
    white-space: wrap;
  }

  .heading-wrapper span {
    font-size: 18px;
  }

  .custom-design .heading-wrapper {
    width: 100%;
    padding: 15px 0;
  }

  .explore-collection .item .img-box {
    height: 275px;
  }

  .explore-collection .slick-initialized .slick-slide {
    padding: 0 10px;
  }

  .golden-btn {
    font-size: 16px;
  }

  .inner-shop-diamond-shape {
    padding: 0 30px;
  }

  .two_ring_cards_section .card_container {
    flex-direction: column;
    gap: 30px;
  }

  .two_ring_cards_section .card_container div {
    flex: auto;
    width: 100%;
    margin: auto;
  }

  .two_ring_cards_section .card_container .img-box {
    max-width: 400px;
    width: 100%;
    height: 400px;
  }

  .two_ring_cards_section .card_container a h3 {
    margin: 20px 0 15px 0;
    font-size: 24px;
  }

}

@media(max-width:425px) {

  .home-showcase-container,
  .LandingPageShocase .showcase-container,
  .rcs_footer .showcase-container,
  .rcs_footer_bottom .showcase-container,
  .showcase-container {
    padding: 0 10px;
  }

  .rcs_banner_video .video-box {
    /* height: 390px; */
  }

  .rcs_banner_video .video-box::before {
    /* height: 228px; */
  }

}

@media(max-width:768px) {
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }

  .about_manuel_section .right_section h2 {
    font-size: 28px;
  }

  .about_manuel_section .right_section .img-box .logo {
    max-width: 60%;
  }
}