/* new product details image  */
/************************* Grid layout for product images *************************/
.ms_pro_detail_img_tile_view_grid {
	display: grid;
	gap: 8px;
	padding: 20px;
	/* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(2, 50%); 
  /* grid-template-rows: repeat(2, 50%); */
}
.ms_pro_detail_img_tile_view_img {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.ms_pro_detail_img_tile_view_img img {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease;
}
.ms_pro_detail_img_tile_view_img .iiz {
	max-width: 100%;
	height: 100%;
}
.ms_pro_detail_img_tile_view_img .iiz__img {
	max-width: 80%;
	margin: 0 auto;
}
.ms_pro_detail_img_tile_view_img .iiz__btn.iiz__hint {
  opacity: 0;
}
.ms_pro_detail_img_tile_view_img figure.iiz:before {
  background: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: .03;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
.ms_some_other_metal p {
  font-size: 14px;
  /* font-weight: 500; */
  color: #000;
  line-height: 25px;
  margin: 15px 0;
  letter-spacing: .5px;
}
.ms_some_other_metal p a {
  text-decoration: underline;
  font-weight: 700;
  color: #000;
}
.ms_pd_video_width_height {
	width: 380px;
	height: 380px;
	object-fit: contain;
}
/******************** Grid layout for product images end ************************/
/*Slider Css*/
section.rcs_related_product_section .mg_nopadd {
  padding-right: 20px;
  margin: 0;
}

section.rcs_related_product_section .rcs_customer_review_slider {
  margin: 0px 0px;
}

section.rcs_related_product_section .rcs_product_item.position-relative {
  margin-bottom: 0;
}

section.rcs_related_product_section .hover-buttons {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
}

.rcs_related_product_title {
  margin-bottom: 30px;
}

.image-gallery-image {
  display: none !important;
}

.magnify-Image {
  /* width: 650px; */
  height: auto;
  margin: 0 auto;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  white-space: unset !important;
}

.image-gallery-thumbnail {
  width: 74px !important;
  height: 74px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 70px !important;
  height: 70px;
  object-fit: contain;
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
  margin-left: 0 !important;
}

.image-gallery-thumbnails-container button {
  margin-bottom: 10px !important;
}

.rcs_metal_break {
  display: block;
  padding: 7px 10px;
}

/*Slider Css*/


/*Content css*/
.rcs_border_btn {
  /* padding: 12px !important;
  border-radius: 0 !important;
  text-transform: capitalize !important;
  letter-spacing: 0 !important; */
}

.rcs_border_btn svg {
  margin-right: 5px;
}

.rcs_border_btn.rcs_border_btn1 {
  width: 48.95%;
  text-transform: capitalize;
}

.rcs_border_btn.rcs_border_btn2 {
  width: 60%;
  text-transform: capitalize;
  font-size: 12px;
}

.rcs_border_btn.rcs_border_btn3 {
  width: 37.9%;
  text-transform: capitalize;
  font-size: 12px;
}

.rcs_border_btn.rcs_border_btn1 {
  width: 48.95%;
}

.rcs_acc_button1,
.rcs_acc_button1:hover,
.rcs_acc_button1:active,
.rcs_acc_button1:focus {
  padding: 12px 0 !important;
}

.rcs_product_heading h1 {
  font-family: var(--font_3);
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary);
  margin-bottom: 15px;
}

.rcs_email span {
  margin: 17px 0;
  display: block;
  font-size: 1.3rem !important;
  font-weight: bold !important;
  text-transform: none !important;
  text-shadow: none;
  line-height: 1.00;
  letter-spacing: .125rem;
}

.rcs_product_heading {}

.rcs_product_specification h2 {
  text-align: left;
  font-weight: 500;
}

.rcs_related_product_title h3 {
  font-weight: 400;
  text-transform: uppercase;
}

.rcs_price h4 {
  font-family: var(--font_3);
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.rcs_content_details p {
  font-family: var(--font_1);
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0 0px;
}

.rcs_social ul {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  margin-bottom: 30px;
}

.rcs_social ul li {
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 500;
  transition: all .2s ease-in-out;
  cursor: pointer;
  color: var(--primary);
  margin-right: 30px;
  text-align: center;
}

.rcs_social ul li span {
  /* display: block; */
  white-space: nowrap;
}

.rcs_social ul li i {
  background: var(--primary);
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}
.rcs_social ul li svg {
  background: var(--primary);
  width: 20px;
  height: 20px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  padding: 3px;
}
.rcs_social ul li:hover {
  transform: translateY(-4px);
}

.rcs_ring_style_type p {
  text-align: center;
  margin-top: 10px;
}

.rcs_product_details {
  padding: 30px 0px 0;
}

/*Content css*/
/*Specification css*/
.rcs_product_specification h2 {
  font-family: 'Gotham';
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: var(--primary);
  margin-bottom: 20px;
}

section.rcs_related_product_section {
  padding-top: 50px;
}

.rcs_product_specification_content {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #333;
  padding: 15px 30px;
}

.rcs_product_specification_content strong {
  max-width: 175px;
  width: 100%;
  display: inline-block;
  font-weight: 500;
}

.rcs_product_specification_content p {
  font-weight: 400;
  margin: 0;
  display: inline-block;
  padding-left: 80px;
  color: #000;
}

.rcs_product_specification .row .col-md-6 .rcs_product_specification_content:nth-of-type(odd),
.rcs_product_specification .row .col-lg-10 .rcs_product_specification_content:nth-of-type(odd),
.rcs_product_specification .row .col-md-12 .rcs_product_specification_content:nth-of-type(odd) {
  background: #F5F8F9;
}


/*Specification css*/
/*Review Section*/
.rcs_rating_overall {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.125rem;
  height: 100%;
  text-align: center;
}

.rcs_rating_overall h2 {
  display: block;
  width: 100%;
  line-height: 50px;
  font-size: 40px;
  font-weight: 800;
  padding: 10px;
  background: 0;
  padding-bottom: 30px;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--font_1);
}

.rcs_rating_overall .rcs_out_of {
  display: block;
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 1px;
  width: calc(100% - 4px);
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
}

.rcs_rating_progress ul li {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.rcs_rating_progress ul li p {
  margin: 0;
  font-weight: 600;
}

.rcs_rating_overall_star,
.rcs_rating_overall_total {
  padding: 0.75rem 0 0.85rem 0;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.125rem;
  height: 100%;
  text-align: center;
}

.rcs_rating_overall_star h3,
.rcs_rating_overall_total h3 {
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0.25rem;
  padding-top: 0.25rem;
  font-family: var(--font_1);
}

.rcs_rating_overall_star span.MuiRating-root.MuiRating-sizeMedium.MuiRating-readyOnly.css-ryrseu-MuiRating-root {
  justify-content: center;
}

.rcs_rating_overall_total p {
  font-size: 12px;
  line-height: 1.1;
  padding: 0 1rem;
  font-weight: 600;
  margin: 0;
}

.rcs_rating_overall {
  width: 20%;
  float: left;
}

.rcs_rating_progress {
  width: 75%;
  float: left;
  margin: 0 10px;
}

.rcs_rating_overall_star {
  width: 48.3%;
  float: left;
  margin: 0 15px;
}

/* .rcs_rating_overall_total {
      width: 47%;
      float: left;
  } */
.video-wrapper iframe {
  border: none;
}

.video-wrapper iframe {
  width: 100% !important;
  height: 570px !important;
}

.rcs_product_specification_bottom_content img {
  border-radius: 50%;
  max-width: 100%;
  width: 70px;
  height: 70px;
  margin-right: 1rem !important;
}

.rcs_product_specification_bottom_content {
  padding: 1rem;
  margin-top: 1.3rem;
  border-radius: 0.25rem;
}

.rcs_product_specification_bottom_content label {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
}

.rcs_product_specification_bottom_content .media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.rcs_product_specification_bottom_content .media-body {
  -ms-flex: 1;
  flex: 1;
}

.rcs_product_specification_bottom_content p {
  font-family: var(--font_1);
  font-size: 13px;
  font-weight: inherit;
}

.rcs_product_specification_top .container {
  margin-top: 10px;
}

.rcs_write_review {
  margin-top: 15px !important;
}

.rcs_product_specification_bottom small {
  font-size: 13px;
}

.rcs_write_review h2 {
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: none;
  line-height: 1.00;
  letter-spacing: .125rem;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  font-family: var(--font_1);
}

.rcs_write_review button {
  height: 50px;
  font-size: 16px;
  letter-spacing: .125rem;
  font-style: normal;
  font-family: var(--font_1);
  font-weight: 500 !important;
  line-height: 18px !important;
}

.rcs_review_btn,
.rcs_review_btn:hover,
.rcs_review_btn:active,
.rcs_review_btn:focus {
  margin: 0 auto !important;
  display: block !important;
}

span.rcs_inv_msg {
  text-align: right;
  padding-top: 5px;
  padding-right: 4px;
  font-size: 12px;
}

/*Review Section*/
/*loader Section*/
.mj-filter-loader {
  margin: 0 auto;
}

/*loader Section*/

/*Shipping Section css*/
.rcs_shipping_content.mt-2.mb-5 h2 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: none;
  line-height: 1.00;
  letter-spacing: .125rem;
  font-family: var(--font_1);
}

.rcs_shipping_content ul {
  padding-left: 30px;
  list-style: disc;
}

.rcs_return_modal.modal.show .modal-dialog {
  max-width: 50% !important;
}

hr.MuiDivider-root {
  margin: 20px 0 !important;
}

.rcs_shipping_content span {
  width: 100%;
  display: block;
}

/*Shipping Section css*/
.rcs_add_address .rcs_details_ring_size.animate__shakeX .MuiSelect-root {
  border-radius: 4px !important;
}

.rcs_add_address .rcs_details_ring_size.animate__shakeX svg {
  font-weight: unset !important;
}

.rcs_add_address .rcs_details_ring_size.animate__shakeX .MuiInputLabel-formControl {
  padding: 0px 3px !important;
  margin-top: -2px !important;
}

/* legend.MuiTypography-root.rcs_add_address .rcs_details_ring_size.animate__animated.animate__shakeX.MuiTypography-body1 {
      color: red;
  }  */
.rcs_border_btn_border {
  border: none !important;
}

.rcs_border_btn_border i {
  font-size: 16px;
  margin-right: 10px;
}

/*Server css*/
.rcs_rating_overall_star span.css-1fwwzvr {
  justify-content: center;
}

/*Server css*/
.rcs_wish_btn_detail {
  /* width: 49% !important;
  margin-right: 10px !important;
  padding: 8px 0px !important;
  font-size: 12px; */
}

.rcs_appoint_btn_detail {
  width: 48% !important;
  padding: 8px 0px !important;
  font-size: 12px;
}

.irs-handle i {
  display: none !important;
}

span.irs-handle {
  display: block !important;
  border: 2px solid #FFFFFF;
  background: var(--primary) !important;
  border-radius: 100%;
  width: 25px !important;
  height: 25px !important;
  box-shadow: 0px 1px 4px 0px #79797973;
}

body .irs--flat .irs-from,
.irs--flat .irs-to,
body .irs--flat .irs-single {
  background-color: var(--primary) !important;
  border-radius: 0 !important;
  font-family: 'Gotham';
  font-size: 10px;
  font-weight: 500;
}

body .irs--flat .irs-from:before,
body .irs--flat .irs-to:before,
body .irs--flat .irs-single:before {
  border-top-color: var(--primary) !important;
}

body .irs--flat .irs-line {
  top: 30px;
  height: 4px;
}

body .irs--flat .irs-bar {
  top: 30px;
  height: 4px;
  background: var(--primary);
}

.irs-min,
.irs-max {
  display: none !important;
}

/*Resoponsive css*/
@media (min-width:1024px) {
  .rcs_p0 {
    padding: 0px;
  }
}

@media (max-width:575px) {
  .video-wrapper video {
    width: 100%;
  }

  .rcs_product_heading h1 {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 15px;
    padding: 0 !important;
  }

  .rcs_product_heading button.shadow-none.rcs_border_btn {
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    left: 13px;
  }

  .rcs_price h4 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .rcs_product_details {
    padding: 0;
  }

  .ml_product_content {
    /* text-align: center !important; */
  }

  .rcs_social li i {
    font-size: 16px;
    vertical-align: top;
  }

  .rcs_rating_overall {
    width: 30%;
  }

  .rcs_rating_progress {
    width: 63%;
  }

  .rcs_rating_overall_star {
    width: 43%;
    margin: 20px 6px;
  }

  .rcs_rating_overall_total {
    width: 50%;
    margin: 20px 0px;
  }

  .rcs_rating_progress ul li p {
    font-size: 12px;
  }

  .rcs_border_btn.rcs_border_btn2 {
    width: 100%;
  }

  .rcs_border_btn.rcs_border_btn1 {
    width: 44.95%;
  }

  .rcs_border_btn.rcs_border_btn3 {
    width: 100%;
  }
}

@media (max-width:339px) and (min-width:320px) {
  .rcs_product_specification_bottom small.float-right {
    float: left !important;
  }
}

@media (max-width:359px) and (min-width:320px) {
  .video-wrapper video {
    width: 100%;
    height: 285px;
  }

  /* add to cart style */
  .rcs_product_specification_content {
    padding: 10px 0 0px 0;
  }

  .rcs_border_btn.rcs_border_btn1 {
    width: 48.5%;
  }

  .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.rcs_border_btn.rcs_border_btn3.mb-3.ml-1.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin-left: 0 !important;
  }

  .progress {
    height: 0.8rem;
  }

  .rcs_rating_progress ul li p {
    font-size: 10px;
  }

  .rcs_rating_overall_star {
    margin-left: 0;
  }

  .rcs_rating_overall_star {
    width: 47.9%;
  }

  .magnify-Image {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .order-last.order-sm-first.col-sm-2 {
    padding: 0;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 50px !important;
    height: 50px;
    object-fit: cover;
  }

  .image-gallery-thumbnail {
    width: 64px !important;
    height: 64px !important;
  }

  .rcs_return_modal.modal.show .modal-dialog {
    max-width: 100% !important;
  }

  /* add to cart style */
  .rcs_wish_btn_detail {
    width: 100% !important;
  }

  .rcs_appoint_btn_detail {
    width: 100% !important;
  }

}

@media (max-width:575px) and (min-width:360px) {

  .video-wrapper,
  .video-wrapper video,
  .image-to-magnify {
    width: 100%;
    /* height: 252px; */
  }

  .rcs_rating_overall h2 {
    font-size: 30px;
  }

  .rcs_rating_overall_star h3 {
    font-size: 1rem;
  }

  .rcs_rating_overall_star span.MuiRating-root.MuiRating-sizeMedium.MuiRating-readyOnly.css-ryrseu-MuiRating-root {
    font-size: 18px;
  }

  .rcs_rating_overall_total h3 {
    font-size: 1rem;
  }

  /* add to cart style */
  button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.rcs_border_btn.rcs_border_btn3.mb-3.ml-1.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin-left: 0 !important;
  }

  .rcs_border_btn.rcs_border_btn1 {
    /* width: 45.95% !important; */
  }

  .rcs_border_btn.rcs_border_btn1.mb-3.ml-1.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin-left: 1.35rem !important;
  }

  .rcs_product_specification_content {
    padding: 0.5rem 0 0.5rem 0;
  }

  .rcs_rating_overall_star {
    margin-left: 0;
  }

  .rcs_rating_overall_total {
    width: 55%;
  }

  .progress {
    height: 0.8rem;
  }

  .rcs_rating_progress ul li p {
    font-size: 10px;
  }

  .magnify-Image {
    width: 70%;
    margin: 0 auto;
    display: block;
  }

  .order-last.order-sm-first.col-sm-2 {
    padding: 0;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 50px !important;
    height: 50px;
    object-fit: cover;
  }

  .image-gallery-thumbnail {
    width: 64px !important;
    height: 64px !important;
  }

  .rcs_return_modal.modal.show .modal-dialog {
    max-width: 100% !important;
  }

  .rcs_social li i {
    /* font-size: 16px !important; */
  }

  .rcs_social ul li {
    font-size: 11px;
  }

  /* add to cart style */
  .rcs_wish_btn_detail {
    width: 100% !important;
  }

  .rcs_appoint_btn_detail {
    width: 100% !important;
  }
}

@media (max-width:767px) and (min-width:576px) {
  .rcs_rating_overall {
    width: 27%;
  }

  .rcs_rating_progress {
    width: 63%;
  }

  .rcs_rating_overall_star {
    width: 43%;
    margin: 5px 6px;
  }

  .rcs_rating_overall_total {
    width: 50%;
    margin: 5px 0px;
  }

  .rcs_rating_progress ul li p {
    font-size: 13px;
  }

  .rcs_rating_overall_star h3 {
    font-size: 1.0rem;
  }

  .rcs_rating_overall h2 {
    font-size: 30px;
  }

  .rcs_rating_overall_star span.MuiRating-root.MuiRating-sizeMedium.MuiRating-readyOnly.css-ryrseu-MuiRating-root {
    justify-content: center;
    font-size: 18px;
  }

  .rcs_return_modal.modal.show .modal-dialog {
    max-width: 90% !important;
  }
}

@media (max-width:991px) and (min-width:768px) {
  .rcs_rating_overall {
    width: 27%;
  }

  .rcs_rating_progress {
    width: 63%;
  }

  .rcs_rating_overall_star {
    width: 43%;
    margin: 5px 6px;
  }

  .rcs_rating_overall_total {
    width: 50%;
    margin: 5px 0px;
  }

  .rcs_rating_progress ul li p {
    font-size: 13px;
  }

  .rcs_rating_overall_star h3 {
    font-size: 1.0rem;
  }

  .rcs_rating_overall h2 {
    font-size: 30px;
  }

  .rcs_rating_overall_star span.MuiRating-root.MuiRating-sizeMedium.MuiRating-readyOnly.css-ryrseu-MuiRating-root {
    justify-content: center;
    font-size: 18px;
  }

  /* add to cart style */
  button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.rcs_border_btn.rcs_border_btn1.mb-3.ml-1.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin-left: 0.6rem !important;
  }

  button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.rcs_border_btn.rcs_border_btn3.mb-3.ml-1.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    margin-left: 0.5rem !important;
  }

  /* add to cart style */
}

@media (min-width:992px) and (max-width:1199px) {
  .rcs_product_details .container {
    max-width: 720px;
  }

  .rcs_large_tablet.col-lg-8.col-md-12.col-sm-12,
  .rcs_large_tablet.col-lg-4.col-md-12.col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .rcs_border_btn.rcs_border_btn1 {
    width: 49.4%;
  }

  .rcs_border_btn svg {
    margin-right: 5px;
    font-size: 21px;
  }

  .rcs_border_btn.rcs_border_btn3 {
    width: 38.8%;
  }
}

/**diamond details zoom css **/
@media (min-width:1200px) and (max-width:1440px) {
  .ms_pd_video_width_height {
    width: 270px;
    height: 270px;
  }
  .image-gallery-thumbnail {
    height: 60px !important;
    width: 60px !important;
  }

  .magnify-Image {
    height: auto;
    margin: auto;
    /* width: 530px; */
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100% !important;
    height: 100%;
    object-fit: contain;
  }
}

/**diamond details zoom css **/

@media (min-width:1200px) and (max-width:1440px) {

  .rcs_border_btn.rcs_border_btn2,
  .rcs_border_btn.rcs_border_btn3 {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }

  .rcs_border_btn.rcs_border_btn1 {
    width: 48.86%;
    font-size: 12px;
  }
}


/*Resoponsive css*/




/*Engraving css*/
.gs_engraving {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 3px;
  box-shadow: rgb(51 51 51 / 15%) 0px 1px 4px 0px;
}

.gs_engraving_true .MuiSelect-root {
  border: solid 0.5px green !important;
  color: green !important;
  border-radius: 0px;
}

.eng_del_icon {
  color: #113776;
  cursor: pointer;
}

p.eng_sub_lbl {
  font-size: 11px;
  padding-top: 8px;
  margin-bottom: 0px;
}

p.eng_sub_lbl svg {
  fill: green;
  margin-right: 3px;
}

.eng_color_fill .MuiInputLabel-root {
  color: green !important
}

.eng_color_fill .MuiOutlinedInput-notchedOutline {
  border-color: green !important
}

.gs_gift_bx {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 3px;
}

.gs_ringbox img {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

.gs_mod_pd0 {
  padding: 20px 0px 0px 0px;
}

.gs_engraving_input .MuiTextField-root {
  width: 100%;
}

.gs_engraving img {
  width: 100%;
  margin-bottom: 10px;
}

.gs_engraving img {
  width: 100%;
  margin-bottom: 11px;
}

.gs_engraving_text2 {
  width: 100%;
  margin-top: 25px;
}

.gs_engraving_text2 ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gs_engraving_text2 ul li {
  margin-right: 7px;
  padding: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
}

span.gs_great_vibes {
  font-family: 'Kaushan Script' !important;
  padding: 0 2px;
}

.gs_engraving_text2 ul li.active {
  border: 1px solid #113776;
}

.gs_engraving_text1 h2 {
  font-size: 15px;
  margin: 0;
  display: flex;
  align-items: center;
}

.gs_engraving_text1 h2 span.icon svg {
  fill: green;
  margin-left: 5px;
  width: 0.9em;
}

.gs_engraving_text1 p {
  font-size: 12px;
  text-decoration: underline;
  text-align: right;
  margin: 0;
  font-weight: 600;
}

.gs_engraving_btn svg {
  margin-left: 5px;
}

.gs_engraving_text1 h2 span {
  display: inline-block;
}

.gs_engraving_text span.let {
  height: 500px;
  position: absolute;
  left: 47%;
  z-index: 10;
}

.gs_engraving_text h2 {
  position: absolute;
  text-align: center;
  color: white;
  width: 65%;
  top: 66%;
  z-index: 1;
}

div.circTxt {
  display: inline-block;
  margin-bottom: 128px;
  color: #000;
}

.wrapper h1 {
  font-size: 13px;
  color: black;
  text-align: center;
  position: absolute;
  left: 0%;
  top: calc(0% - 0px);
  transform: translate(50%, calc(-38% - -24px));
  width: 150px;
  height: 20px;
}

.gs_engraving_text {
  position: relative;
  display: inline;
}

.wrapper img {
  position: absolute;
  top: 30px;
  left: 100%;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 340px;
  height: 80px;
  transform: translate(-5%, -50%);
}

.wrapper {
  position: relative;
  top: 0%;
  left: 0%;
  width: 0px;
  height: 100%;
  margin-left: 20px !important;
}

.w-100.m-auto.gs_engraving_input.row {
  margin-top: 105px !important;
}

.wrapper h1 span {
  position: absolute;
}

.gs_ringbox h6 {
  font-weight: 600;
}

.gs_ringbox svg {
  color: #008000ad;
  font-size: 30px;
  position: absolute;
  right: 17px;
  top: 15px;
}

.gs_ringbox {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 23px;
  position: relative;
  cursor: pointer;
}

.p0 {
  padding: 5px;
}

p.gs_montserrat {
  font-family: 'Montserrat';
}

p.gs_great_vibes {
  font-family: 'Kaushan Script';
}

/*Engraving New css*/
@media (max-width: 767px) {
  .video-wrapper iframe {
    width: 100% !important;
    height: 382px !important;
  }

  .rcs_product_details ul.slick-dots {
    z-index: 99;
  }
}

@media (max-width: 400px) {
  .wrapper h1 {
    width: 130px;
    /* transform: translate(-50%, calc(-38% - -30px)); */
  }

  .wrapper img {
    /* transform: translate(-50%, -40%); */
    width: auto;
  }
}

/* @media (min-width: 320px) and (max-width: 359px) {
  .wrapper h1 {
    width: 130px;
    transform: translate(-50%, calc(-38% - -30px));
  }

  .wrapper img {
    transform: translate(-50%, -40%);
    width: auto;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .wrapper img {
    width: 290px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 145px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 375px) and (max-width: 389px) {
  .wrapper img {
    width: 300px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 150px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 390px) and (max-width: 409px) {
  .wrapper img {
    width: 320px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 160px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 410px) and (max-width: 420px) {
  .wrapper img {
    width: 345px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 173px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 421px) and (max-width: 430px) {
  .wrapper img {
    width: 355px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 178px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 431px) and (max-width: 450px) {
  .wrapper img {
    width: 370px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 185px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 451px) and (max-width: 480px) {
  .wrapper img {
    width: 390px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 195px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 481px) and (max-width: 510px) {
  .wrapper img {
    width: 410px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 205px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 511px) and (max-width: 575px) {
  .wrapper img {
    width: 440px;
    transform: translate(-50%, -40%);
  }

  .wrapper h1 {
    width: 220px;
    transform: translate(-50%, calc(-38% - -30px));
  }
}

@media (min-width: 576px) and (max-width: 600px) {
  .wrapper img {
    transform: translate(10%, -40%);
    width: 400px;
  }

  .wrapper h1 {
    width: 200px;
    transform: translate(70%, calc(-38% - -32px))
  }
}

@media (min-width: 601px) and (max-width: 650px) {
  .wrapper img {
    transform: translate(10%, -40%);
    width: 420px;
  }

  .wrapper h1 {
    width: 210px;
    transform: translate(70%, calc(-38% - -32px))
  }
}

@media (min-width: 651px) and (max-width: 700px) {
  .wrapper img {
    width: 440px;
  }

  .wrapper h1 {
    width: 220px;
  }
}

@media (min-width: 701px) and (max-width: 766px) {
  .wrapper img {
    transform: translate(0%, -50%);
    width: 500px;
  }

  .wrapper h1 {
    width: 250px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .gs_engraving {
    width: 100%;
    margin: 0 auto 5px;
  }

  .wrapper img {
    transform: translate(60%, -40%);
    width: 300px;
  }

  .wrapper h1 {
    transform: translate(170%, calc(-15% - -26px));
    width: 150px;
  }

  .gs_details_tablet3 {
    text-align: center;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .gs_engraving {
    width: 100%;
  }

  .wrapper img {
    transform: translate(60%, -40%);
    width: 300px;
  }

  .wrapper h1 {
    transform: translate(174%, calc(0% - -26px));
    width: 150px;
  }
} */

/*Engraving New css*/

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left !important;
}

/* New css priyanka */

.rcs_product_sku p {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0px;
}

.rcs_diamond_funtion .diamond-type {
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 500;
  color: var(--primary);
  vertical-align: middle;
}

.diamond-type svg {
  margin-right: 5px;
}

.rcs_diamond_funtion span {
  font-family: 'Gotham';
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  padding: 0 6px;
}

.rcs_diamond_funtion img {
  margin-right: 8px;
  margin-left: 3px;
  width: auto;
}

hr.h-line {
  margin: 50px 0;
}

.rcs_product_specification {
  padding: 0 0px 50px;
}

.appointment-report-btn {
  margin: 10px 0 20px;
}

.appointment-report-btn .rcs_border_btn {
  font-family: 'Gotham';
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
  background: #DFE4E8;
  text-align: center;
  height: 50px;
  padding: 0 20px;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
}

.appointment-report-btn .rcs_border_btn.bg-dark {
  background-color: var(--primary) !important;
  color: #fff;
}

.appointment-report-btn .rcs_border_btn svg {
  position: relative;
  top: -2px
}

/* .appointment-report-btn .rcs_border_btn:hover{
  background-color: var(--primary);
  color: #fff;
}
.appointment-report-btn .rcs_border_btn:hover svg {
  filter: invert(1) brightness(2.5);
} */
.hLine {
  margin-bottom: 30px;
}

.promisection p {
  font-family: Gotham;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 15px;
}

.promisectionInner svg {
  margin-right: 30px;
}

.video-wrapper {
  margin: auto;
}





.showcase_Ringsetting .rcs_product_specification_content strong {
  max-width: 20%;
}

.ShowcaseRingseting_ProdFilter {
  display: flex;
  flex-wrap: wrap;
}

.ShowcaseRingseting_ProdFilter li {
  text-align: center;
  margin: 0px 10px 5px 0;
}

.ShowcaseRingseting_ProdFilter li img,
.ShowcaseRingseting_ProdFilter li svg {
  display: inline-block;
  border: 1px solid #DFE4E8;
  width: auto;
  height: 65px;
  padding: 8px;
}

.ShowcaseRingseting_ProdFilter li span {
  display: block;
  font-family: 'Gotham';
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}

.ShowcaseRingseting_ProdFilter li.active svg,
.ShowcaseRingseting_ProdFilter li.active img {
  box-shadow: 0px 4px 20px 0px #0000001A;
  background: #FFFFFF;
}

.ShowcaseRingseting_ProdFilter li.active span {
  font-weight: 500;
}

.selectedOptions {
  font-family: 'Gotham';
  font-size: 14px !important;
  font-weight: 400;
}

p.selectedOptions {
  margin-bottom: 10px;
}

.selectedOptions span {
  font-weight: 500;
}

.ShowcaseRingseting_Prodselect .form-select {
  border: 1px solid #DFE4E8;
  height: 50px;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 400;
  color: #000;
  border-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  appearance: none;
  width: 100%;
  padding: 0 20px;
}

.ShowcaseRingseting_Prodselect label {
  font-weight: 500;

}

.ShowcaseRingseting_Prodselect label span {
  font-weight: 400;
}

.ShowcaseRingseting_ProdFilterMain,
.ShowcaseRingseting_Prodselect {
  margin-bottom: 15px;
}

.ShowcaseRingseting_Prodselect .downArrow {
  position: absolute;
  right: 15px;
  top: 20px;
}

hr.hrRingSetting {
  margin: 25px 0;
}

.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}
@media (min-width:1280px) and (max-width:1439px) {
  .ShowcaseRingseting_ProdFilter li span {
    font-size: 11px;
  }

  .rcs_product_specification {
    padding: 0 0px 20px;
  }

  .engListFilterStyle li.rcs_ring_setting_style.rcs_ring_setting_style_mobile img {
    padding: 0;
    width: 100% !important;
  }

  .rcs_product_details {
    padding: 20px 0px;
  }

  .rcs_product_heading h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .rcs_content_details p {
    margin-bottom: 12px;
  }

  hr.hrRingSetting {
    margin: 16px 0 !important;
  }

  .ShowcaseRingseting_Prodselect .form-select,
  .rcs_border_btn {
    height: 40px !important;
  }

  .ShowcaseRingseting_ProdFilter li img,
  .ShowcaseRingseting_ProdFilter li svg {
    height: 55px !important;
  }

  hr.h-line {
    margin: 15px 0;
  }

  .appointment-report-btn .rcs_border_btn,
  .btn.btn-contained {
    font-size: 13px !important;
  }

  /* .rcs_product_sku p, */
  .rcs_product_heading h1,
  .rcs_price h4 {
    margin-bottom: 10px;
  }
}

@media (min-width:991px) and (max-width:1279px) {
  .rcs_product_details {
    padding: 20px 0px;
  }

  .magnify-Image {
    width: 100%;
    height: auto;
  }

  .rcs_product_heading h1,
  .rcs_price h4 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .rcs_product_details .rcs_large_tablet .slick-slide img {
    width: 100%;
  }


  .rcs_diamond_funtion .diamond-type,
  .rcs_diamond_funtion span {
    font-size: 15px;
  }

  .appointment-report-btn .rcs_border_btn,
  .appointment-report-btn .btn.btn-contained {
    font-size: 13px;
    padding: 0 6px;
  }

  .rcs_product_specification_content {
    font-size: 14px;
    padding: 10px 10px;
  }

  .rcs_product_specification {
    padding: 0 0px 30px;
  }

  .appointment-report-btn .rcs_border_btn svg {
    display: none;
  }

  .video-wrapper iframe,
  .image-to-magnify img,
  .video-wrapper video {
    width: 100% !important;
    height: 464px !important;
    object-fit: contain;
  }
}
@media (max-width:991px) {

  .rcs_product_details .slick-prev:before,
  .rcs_product_details .slick-next:before {
    color: var(--primary);
  }

  .rcs_product_details .slick-prev {
    left: 0px;
    z-index: 99;
  }

  .rcs_product_details .slick-next {
    right: 0px;
    z-index: 99;
  }

  .rcs_diamond_funtion .diamond-type,
  .rcs_diamond_funtion span {
    font-size: 14px;
  }

  .diamond-type svg {
    width: 14px;
  }

  .rcs_diamond_funtion span {
    padding: 0 3px;
  }

  .appointment-report-btn .rcs_border_btn {
    margin-bottom: 10px;
  }

  .rcs_social ul li {
    margin-right: 20px
  }

  .rcs_social ul {
    justify-content: center;
    margin-bottom: 18px;
  }

  .appointment-report-btn {
    margin: 20px 0;
  }

  hr.h-line {
    margin: 25px 0;
  }

  .rcs_product_specification {
    padding: 0 0px 20px;
  }

  .rcs_product_specification_content {
    padding: 10px;
  }

  .rcs_product_specification_content p {
    padding-left: 25px;
  }

  .rcs_product_specification_content strong {
    max-width: 150px !important;
  }

  .rcs_product_specification_content {
    font-size: 12px;
  }

  .rcs_product_sku p {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 60px;
    height: 60px;
    padding: 9px;
  }

  .ShowcaseRingseting_ProdFilter li {
    margin: 0px 10px 10px 0;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap;
  }

  .ShowcaseRingseting_ProdFilter li span {
    font-size: 10px;
    /* margin-top: -12px; */
  }

  .rcs_product_details .slick-slide img,
  .rcs_product_details .slick-slide video {
    max-height: 360px;
    object-fit: contain;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .rcs_product_details {
    padding: 20px 0px;
  }

  .video-wrapper video,
  .video-wrapper,
  .image-to-magnify {
    height: 500px !important;
    margin: auto !important;
    display: table;
    max-width: 100%;
    width: 100%;
  }

  .rcs_product_details .slick-slide img {
    width: auto;
    height: 500px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {
  .ShowcaseRingseting_ProdFilter li span {
    font-size: 11px;
  }

  hr.h-line {
    margin: 30px 0;
  }

  .rcs_product_heading h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .rcs_content_details p {
    margin-bottom: 12px;
  }

  hr.hrRingSetting {
    margin: 16px 0 !important;
  }

  .ShowcaseRingseting_Prodselect .form-select,
  .rcs_border_btn {
    height: 40px !important;
  }

  .ShowcaseRingseting_ProdFilter li img,
  .ShowcaseRingseting_ProdFilter li svg {
    height: 55px;
  }

  .ShowcaseRingseting_ProdFilter li {
    margin: 0px 12px 10px 0;
  }
}

@media(max-width: 1200px) {
  .rcs_product_details .slick-slider {
    margin: auto;
  }
}

.rcs_product_details .slick-slide img {
  margin: auto;
}

@media(min-width:575px) and (max-width:767px) {
  .rcs_product_details {
    padding: 20px 0px;
  }

  .rcs_product_details .slick-slide img {
    width: auto;
    height: 568px;
  }

  .video-wrapper video,
  .video-wrapper,
  .image-to-magnify {
    height: 568px !important;
    margin: auto !important;
    display: table;
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width:1440px) and (min-width:1300px) {
  .rcs_product_details .slick-slide img {
    width: 100%;
  }
}

@media(min-width:1600px) {
  .ml_product_content .rcs_content_block.w-100 {
    padding-right: 200px;
  }
}